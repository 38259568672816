import React from 'react';

const Hero: React.FC = () => {
  return (
    <section id="hero">
      <h1>Welcome to CapeMayNationalGroup</h1>
      <p>Your trusted partner in real estate investing.</p>
    </section>
  );
};

export default Hero;
