import React from 'react';
import styled from 'styled-components';

const ServicesSection = styled.section`
  padding: 60px 20px;
  background: #2b2b2b;
  text-align: center;
`;

const Heading = styled.h2`
  font-size: 2em;
  margin-bottom: 40px;
  color: #fff;
`;

const ServiceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-around;
  }
`;

const Service = styled.div`
  max-width: 400px;
  margin: 20px;
  text-align: left;
  background: #3a3a3a;
  padding: 20px;
  border-radius: 8px;
`;

const ServiceImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 8px;
`;

const ServiceHeading = styled.h3`
  font-size: 1.5em;
  margin-top: 20px;
  color: #fff;
`;

const ServiceText = styled.p`
  font-size: 1.1em;
  color: #ccc;
`;

const Services: React.FC = () => {
  return (
    <ServicesSection id="services">
      <Heading>Our Services</Heading>
      <ServiceContainer>
        <Service>
          <ServiceImage src="/property-acquisition.jpeg" alt="Property Acquisition" />
          <ServiceHeading>Property Acquisition</ServiceHeading>
          <ServiceText>
            At CapeMayNationalGroup, we specialize in identifying and acquiring high-potential properties that align with your investment goals. Our team conducts thorough market analysis and due diligence to secure prime real estate opportunities.
          </ServiceText>
        </Service>
        <Service>
          <ServiceImage src="/portolio-management.png" alt="Portfolio Management" />
          <ServiceHeading>Portfolio Management</ServiceHeading>
          <ServiceText>
            We offer comprehensive portfolio management services to maximize the value of your real estate investments. From strategic planning to ongoing management, we ensure your assets perform at their best.
          </ServiceText>
        </Service>
      </ServiceContainer>
    </ServicesSection>
  );
};

export default Services;
