import React from 'react';

const About: React.FC = () => {
  return (
    <section id="about">
      <h2>About Us</h2>
      <p>CapeMayNationalGroup is a leading real estate investment firm specializing in high-yield property investments. Our team of experts is dedicated to helping you secure your financial future through strategic real estate opportunities.</p>
    </section>
  );
};

export default About;
